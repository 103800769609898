import { InputBase, InputBaseProps } from "@mui/material";
import classnames from "classnames";
import React from "react";
import { CDataTypography } from "../text/CDataTypography";

// TODO: CLOUD-13935: Create a ticket to move InputBaseProps to a prop inside of CDataTextFieldProps, rather than extending the container props
export interface CDataTextFieldProps extends InputBaseProps {
  label?: string | JSX.Element;
  isInvalid?: boolean;
  helperText?: string;
  ["data-testid"]?: string;
  containerTestId?: string;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  inputBaseClassName?: string;
}

export const CDataTextField = (props: CDataTextFieldProps) => {
  const {
    label,
    isInvalid,
    helperText,
    containerTestId,
    containerProps,
    inputBaseClassName,
    ...inputBaseProps
  } = props;

  let title = label;
  if (label && typeof label === "string") {
    title = (
      <CDataTypography
        variant="typography-variant-headline-5"
        className={classnames("mb-2", { required: props.required })}
      >
        {label}
      </CDataTypography>
    );
  }

  const feedback = helperText && (
    <CDataTypography
      variant="typography-variant-helper-text"
      color={
        isInvalid ? "typography-color-danger" : "typography-color-text-regular"
      }
    >
      {helperText}
    </CDataTypography>
  );

  return (
    <div
      className={classnames("cdata-text-field", props.className)}
      data-testid={containerTestId}
      {...containerProps}
    >
      {title}
      <InputBase
        {...inputBaseProps}
        aria-label={
          label != null && typeof label === "string" ? label : undefined
        }
        className={classnames("form-control py-0", inputBaseClassName, {
          "is-invalid": isInvalid,
        })}
      />
      {feedback}
    </div>
  );
};
