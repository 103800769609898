import { cloneElement, useContext, useRef } from "react";
import { Formik, FormikValues, FormikHelpers, FormikProps } from "formik";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import classnames from "classnames";
import { Container, Form } from "react-bootstrap";

import * as Yup from "yup";
import { IUser } from "../../../models";
import {
  VerifyEmailType,
  generateVerifyEmailModal,
} from "../AccountTab/AccountTabModals";
import VerificationButtons from "./VerificationButtons";
import { RequestType } from "../../../components/withAPI";
import { ToastrSuccess } from "../../../services/toastrService";
import { ModalContext } from "../../../routes/ModalContext";
import { useAPI } from "../../../components/useAPI";
import { CDataFormTextField } from "../../../components/form/CDataFormTextField";
import { UserRole } from "../../../models/Users/UserRole";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { NotificationBar } from "../../../components/notification/NotificationBar";
import { useAppSelector } from "../../../redux/hooks";

export type EmailChangeFormikValuesType = { LoginEmail: string };
interface IEmailChangeCardProps {
  initialValues: EmailChangeFormikValuesType;
  userInfo: IUser;
  setUserInfo: (userInfo: IUser) => void;
  isSSO: boolean;
  isSupportImpersonationActive: boolean;
}

const EmailChangeCard = (props: IEmailChangeCardProps) => {
  const {
    pendingVerificationButton,
    resendVerificationButton,
    cancelVerificationButton,
  } = VerificationButtons({ userInfo: props.userInfo });

  const modalContext = useContext(ModalContext);
  const formikRef = useRef<FormikProps<EmailChangeFormikValuesType>>(null);
  const api = useAPI();
  const user = useAppSelector((u) => u.user);
  const isOemSub = user.role === UserRole.ServiceUser;

  const sendPasswordResetEmail = async () => {
    const { status } = await api.callAPI(
      RequestType.Post,
      "/users/self/resetPassword",
      "Failed to send reset password email due to the following error:",
      null,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      ToastrSuccess(
        "Password Reset Email Sent!",
        "A password reset email has been sent to the email address associated with your login.",
      );
    }
  };

  const sendVerificationEmail = async (email: string) => {
    const data = { EmailAddress: email };
    const { status } = await api.callAPI(
      RequestType.Post,
      "/users/loginEmail",
      "Failed to update user email: ",
      data,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200 || status === 204) {
      const updatedUser = {
        ...props.userInfo,
        pendingEmail: email,
      };

      await props.setUserInfo(updatedUser);

      modalContext.toggleModal();
      ToastrSuccess(
        "Email Verification Successfully Sent!",
        `A verification email was sent to ${email}.`,
      );
    } else if (status === 409) {
      const formikCurrent = formikRef.current;
      if (formikCurrent) {
        const { initialValues } = formikCurrent;
        formikCurrent.setValues({ ...initialValues });
      }
      modalContext.toggleModal();
    }
  };

  const cancelEmailChangeRequest = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>,
    updateUI: boolean,
  ) => {
    const { status } = await api.callAPI(
      RequestType.Delete,
      "/users/loginEmail",
      "Failed to update user email: ",
      null,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      const updatedUser = { ...props.userInfo };
      if (updateUI) {
        updatedUser.pendingEmail = "";
        await props.setUserInfo(updatedUser);
        const formikCurrent = formikRef.current;
        if (formikCurrent) {
          const { touched } = formikCurrent;
          formikCurrent.setValues({
            LoginEmail: updatedUser.email ?? "",
          });
          formikCurrent.setTouched({ ...touched, LoginEmail: false });
        }
        ToastrSuccess(
          "Email Change Request Canceled",
          "The email change you requested has been canceled.",
        );
      }
    }
  };

  async function handleValidSubmit(
    values: EmailChangeFormikValuesType,
    actions: FormikHelpers<EmailChangeFormikValuesType>,
  ) {
    generateVerifyEmailModal(
      values,
      actions.setFieldValue,
      actions.resetForm,
      props.userInfo.pendingEmail!,
      null!,
      sendVerificationEmail,
      modalContext.toggleModal,
      modalContext.setModal,
      VerifyEmailType.Login,
    );
  }

  const validationSchema = Yup.object().shape({
    LoginEmail: Yup.string()
      .email("Must be a valid email address")
      .max(255)
      .required("This is a required field"),
  });

  return (
    <Container fluid className="p-0">
      <Card>
        <CardBody>
          <Formik<EmailChangeFormikValuesType>
            innerRef={formikRef}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(
              values: EmailChangeFormikValuesType,
              actions: FormikHelpers<EmailChangeFormikValuesType>,
            ) => {
              handleValidSubmit(values, actions);
            }}
          >
            {({
              handleSubmit,
              values,
              resetForm,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <Form>
                <Row>
                  <Col>
                    <CDataTypography
                      variant="typography-variant-headline-4"
                      className="mb-4"
                    >
                      Login
                    </CDataTypography>
                  </Col>
                  {props.userInfo?.canResetPassword && (
                    <Col className="text-end">
                      <Button
                        className="card-actions me-2"
                        onClick={sendPasswordResetEmail}
                        data-testid="button-reset-password"
                        disabled={props.isSupportImpersonationActive}
                      >
                        <i className="fa fa-lock small-icon align-middle no-pointer-event" />
                        Reset Password
                      </Button>
                      <Button
                        onClick={() => handleSubmit()}
                        color="primary"
                        className="card-actions float-end"
                        disabled={!(isValid && dirty)}
                        data-testid="button-save-changes-email"
                        hidden={props.isSupportImpersonationActive}
                      >
                        <i className="fa fa-save small-icon align-middle no-pointer-event" />
                        Save Changes
                      </Button>
                    </Col>
                  )}
                </Row>
                {!props.userInfo?.canResetPassword || props.isSSO ? (
                  <Row className="mb-4">
                    <Col>
                      <NotificationBar
                        message="Your account uses single sign-on. Contact your organizations administrator to change your email and/or password used for login."
                        barColor="notification-bar-orange"
                      />
                    </Col>
                  </Row>
                ) : null}
                <Form.Group>
                  <CDataTypography
                    variant="typography-variant-headline-5"
                    className="required mb-2"
                  >
                    Email {pendingVerificationButton}
                  </CDataTypography>
                  <CDataFormTextField
                    name="LoginEmail"
                    type="email"
                    data-testid="LoginEmail"
                    isInvalid={Boolean(props.userInfo?.pendingEmail)}
                    disabled={
                      !props.userInfo?.canResetPassword ||
                      props.isSSO ||
                      props.isSupportImpersonationActive
                    }
                    helperText={
                      !props.userInfo?.canResetPassword
                        ? "Managed by your organization."
                        : undefined
                    }
                    errorMessage={
                      props.userInfo?.pendingEmail
                        ? `All account related notifications will continue to be sent to ${props.userInfo?.email} until you verify the email above.`
                        : undefined
                    }
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
                <Row
                  className={classnames("verification-buttons float-start", {
                    "mt-3": props.userInfo?.pendingEmail,
                  })}
                >
                  <Col className="pe-1">
                    {resendVerificationButton
                      ? cloneElement(resendVerificationButton, {
                          onClick: () =>
                            generateVerifyEmailModal(
                              values,
                              setFieldValue,
                              resetForm,
                              props.userInfo.pendingEmail!,
                              null!,
                              sendVerificationEmail,
                              modalContext.toggleModal,
                              modalContext.setModal,
                              VerifyEmailType.Login,
                            ),
                        })
                      : null}
                  </Col>
                  <Col className="ps-0">
                    {cancelVerificationButton
                      ? cloneElement(cancelVerificationButton, {
                          onClick: (
                            values: FormikValues,
                            actions: FormikHelpers<FormikValues>,
                          ) => cancelEmailChangeRequest(values, actions, true),
                        })
                      : null}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
};

export default EmailChangeCard;
